<script>
import {Line} from "vue-chartjs";
import {Chart as ChartJS} from 'chart.js'

ChartJS.defaults.global.defaultFontFamily = "Poppins";
export default {
    extends: Line,
    props: ['labels', 'data'],
    mounted() {
        this.renderGraph()
    },
    computed: {
        chartData: function () {
            return this.data;
        }
    },
    watch: {
        data: function () {
            this.renderGraph();
        }
    },
    methods: {

        renderGraph() {
            var data = this.data;
            var labels = this.labels;
            this.renderChart(
                {
                    labels,
                    datasets: [
                        {
                            // label: "Data 1",
                            borderColor: "#fbcc02",
                            pointBackgroundColor: "#d60c56",
                            data: data,
                            backgroundColor: "transparent",
                            tension: 0.1
                        }
                    ]
                },
                {
                    height: "100",
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: false,
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [
                            {
                                ticks: {
                                    fontSize: "12",
                                    fontColor: this.$store.state.dark_theme ? "#718096" : "#777777",
                                },
                                gridLines: {
                                    display: false,
                                },

                            }
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    color: this.$store.state.dark_theme ? "#718096" : "#D8D8D8",
                                    zeroLineColor: this.$store.state.dark_theme ? "#718096" : "#D8D8D8",
                                    borderDash: [2, 2],
                                    zeroLineBorderDash: [2, 2],
                                    drawBorder: true
                                },
                                ticks: {
                                    beginAtZero: true,
                                },
                            }
                        ]
                    },
                }
            );
        }
    }
};
</script>